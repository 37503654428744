<template>
  <div class="card flex flex-column mt-1 mb-1 ml-2 p-2 bg-lightNeoGray">
      <div class="flex flex-row">
          <div class="flex flex-column mr-2 p-0">
              <div :class="`card pb-0 m-0 ${showPointer1}`" @click="showChart('value1')">
                  <div class="social-icon">
                      <span :class="`text-4xl ${colorVal_1}`">{{ value_1 }}</span>
                  </div>
                  <span :class="`text-xs ${colorTextLabel}`">{{ valLabel_1 }}</span>
              </div>
          </div>
      </div>
      <div class="flex flex-row justify-content-center mt-1">
          <div class="flex align-items-center justify-content-center">
            <span v-if="widgetLabel" :class="`mr-1 text-sm ${colorTextLabel}`">{{ widgetLabel }}</span>
            <span :class="`text-sm ${colorTextLabel}`">{{ value_1_unit }}</span>
          </div>
      </div>
  </div>
  <dialog-chart-single :icon="iconSymbol" :node="value" v-bind:show="showChart1" @chart-close="showChart1 = false"></dialog-chart-single>
</template>

<script>
import { defineComponent } from "vue";
import dialogChartSingle from '@/components/dialog/chartSingle.vue';

export default defineComponent({
  name: "widgetValue",
  setup() {},
  components: {
    dialogChartSingle,
  },
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Object,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    colorVal: {
      type: String,
      required: false
    },
    zeroPoint: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      showChart1: false,
    }
  },
  watch: {

  },
  computed: {
    widgetLabel() {
      if (this.label !== undefined) return this.label;
      return false;
    },
    iconSymbol() {
      if (this.icon !== undefined) return this.icon;
      else return '';
    },
    colorVal_1() {
      if (this.colorVal !== undefined) return this.colorVal;
      else return `fg-lime`;
    },
    colorTextLabel() {
      return `fg-gray`;
    },
    value_1() {
      if (this.value.value !== undefined) return this.value.value;
      else return "";
    },
    value_1_unit() {
      if (this.value.unit !== undefined) return this.value.unit;
      else return "";
    },
    isRecord1() {
      if (typeof(this.value) === "object") {
        if (this.value.record === "true") return true;
      }
      return false;
    },
    showPointer1() {
      if (this.isRecord1) return "cursor-pointer";
      else return "";
    },
  },
  mounted() {
  },
  methods: {
    showChart(value) {
      if (value === 'value1' && this.isRecord1) this.showChart1 = true;
    },
  },
});
</script>

<style lang="scss" scoped>
</style>